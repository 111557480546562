import React, { useState, useEffect } from "react";
import FilesDataGrid from "../components/FilesDataGrid";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import axios from 'axios';

const apiUrl = process.env.REACT_APP_MAGNIFICO_BASE_URL;

export const DataGridTest = () => {
    const { user } = useAuth0();
    const [files, setFiles] = useState([]);

    console.log("Auth0 User Info:", user);

    const getFilesForCurrentUser = async () => {
        try {
            const encodedUserId = encodeURIComponent(user.sub);
            const url = `${apiUrl}/files?userID=${encodedUserId}`;
            console.log('Constructed URL:', url); // Log the URL
            const response = await axios.get(url);
            // Check if the response status is OK (HTTP 200)
        if (response.status === 200) {
            const filesData = response.data;
            setFiles(filesData);
            
        } else {
        console.error('Failed to retrieve files. Unexpected status code:', response.status);
        }
  
        } catch (error){
            console.error(error);
        };
    };
    useEffect(() => {
        getFilesForCurrentUser();
    }, []);
    

    return(
    <div>
        <div>
            <h2>User String</h2>
            {JSON.stringify(user, null, 2)}
            {JSON.stringify(user.sub, null, 2)}
        </div>

        <div>
            <h2>File String</h2>
            {JSON.stringify(files, null, 2)}
        </div>
        <div>
        <h2>Files</h2>
        <FilesDataGrid />
        </div>
    </div>
    );
};

export default withAuthenticationRequired(DataGridTest, {
    onRedirecting: () => <Loading />,
  });