import {useAuth0} from "@auth0/auth0-react";
import React, { useState, useEffect } from 'react';
import '../transcript.css';
import SideNav from "../components/TranscriptSideNav";
import './view.css'
function Transcript() {
  const [files, setFiles] = useState([]); 
  const [selectedFile, setSelectedFile] = useState(null);
  const { user } = useAuth0();

  function fetchFilesFromMongoDB() {
    const userId = encodeURIComponent(user.sub);
    const apiUrl = `${process.env.REACT_APP_MAGNIFICO_BASE_URL}/transcripts?userID=${userId}`;

    return fetch(apiUrl)
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  useEffect(() => {
    if (user) {
      fetchFilesFromMongoDB()
        .then((data) => {
          setFiles(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [user]);

  // Function to handle file name click
  const handleFileNameClick = (file) => {
    setSelectedFile(file); // Set the selected file when a name is clicked
  };

  return (
    <div className='main-view'>
      <SideNav files={files} onFileClick={handleFileNameClick} />
      <div className="content">
        <h2>Transcript</h2>
        {selectedFile && (
          <div className="file-content">
            <h3>{selectedFile.filename}</h3>
            <p>{selectedFile.transcript}</p>
          </div>
        )}
      </div>
    </div>
  );
 }
 export default Transcript;
