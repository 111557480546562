import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_MAGNIFICO_BASE_URL;

function FilesDataGrid() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useAuth0();

    console.log("Auth0 User Info:", user);

    useEffect(() => {
        setLoading(true);
        const getFilesForCurrentUser = async () => {
            try {
                const encodedUserId = encodeURIComponent(user.sub);
                const url = `${apiUrl}/files?userID=${encodedUserId}`;
                console.log('Constructed URL:', url); // Log the URL
                const response = await axios.get(url);
                // Check if the response status is OK (HTTP 200)
            if (response.status === 200) {
                const filesData = response.data.map((row) => ({
                    id: row._id.$oid,
                    name: row.filename,
                    type: row.type,
                    lastModified: row.lastModified,
                    size: row.size,
                }));
                setRows(filesData);
                
            } else {
            console.error('Failed to retrieve files. Unexpected status code:', response.status);
            }
      
            } catch (error){
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        getFilesForCurrentUser();
    }, []);

    const columns = [
        { field: 'name', headerName: 'Name', width: 500 },
        { field: 'type', headerName: 'Type', width: 150 },
        { field: 'lastModified', headerName: 'Last Modified', width: 200 },
        { field: 'size', headerName: 'Size', width: 100 }
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} pageSize={5} loading={loading} />
        </div>
    );
}

export default FilesDataGrid;
