import React, {useState} from 'react';
import { Button} from 'react-bootstrap';
import { Text, StyleSheet, Pressable } from 'react-native';
import './Product.css'
import {Link} from "react-router-dom";
function Existingproduct () {

    const [isSelect, setSelect] = useState(false);
    
    const handleClick = () => {
        setSelect(!isSelect);
    };
    const outerCircleName = isSelect ? "outer-circle select" : "outer-circle";
    const innerCircleName = isSelect ? "inner-circle select" : "inner-circle";
    const ExistingproductClassName = isSelect ?  "existing-product select" : "existing-product";
    return(
    <div className = {ExistingproductClassName} onClick={handleClick}>
    <label style={{
                color: "#1D2733",
                textAlign: "center",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "23px",
                marginLeft:"5px",
                marginRight:"5px"
                }}>
        <h1 className="product-title">Improve my existing product</h1>
        Build a comprehensive view of your customer feedback, 
            beginning with a free assessment. Pull insights from customer calls 
            and support tickets to prioritize features with data driven precision.
    </label>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle className = {outerCircleName} cx="12" cy="12" r="11" />
    <circle className = {innerCircleName} cx="12" cy="12" r="8" />
    </svg>
    </div>)
};

export default Existingproduct
