import React from 'react';
// import './TranscriptSideNav.css';

function SideNav({ files, onFileClick }) {
  return (
    <div className="side-nav">
      <h2>File List</h2>
      <ul>
        {files.map((file) => (
          <li key={file._id.$oid} onClick={() => onFileClick(file)} style={{ cursor: "pointer" }}>
            {file.filename}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SideNav;