import React from 'react';
import './SignIn.css';

function SignIn() {
  return (
    <div className="SignIn">
      <div className="left-section">
        {/* Add any additional content here */}
      </div>
      <div className="right-section">
        <div className="header-text">Get Started</div>
        <button className="custom-button">Log in</button>
        <button className="custom-button">Sign up</button>
        <div className="footer-text">
          <span>Terms of Service</span> | <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
