import React from "react";

import logo from "../assets/logo.svg";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
    <h1 className="mb-4">Magnifico</h1>

    <p className="lead">
    Welcome to the alpha release of Magnifico. Magnifico is software for product managers that uses AI characters and workflows to facilitate product improvements. Let us know what you think!
    </p>
  </div>
);

export default Hero;
