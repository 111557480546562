import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import FilesView from "./views/FilesView";
import Chat from "./views/Chat";
import Transcript from "./views/Transcript";
import User from "./views/User";
import DataGridTest from "./views/DataGridTest";
import FeaturesView from "./views/Features";
import Header from './components/Header'
import SignIn from "./views/SignIn";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import SelectPathway from "./views/SelectPathway";
initFontAwesome();

const App = () => {
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    redirectUri={window.location.origin}
  >
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <Header/>
        {/* <NavBar /> */}

        <Container className="content">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/external-api" component={ExternalApi} />
            <Route path="/file-upload" component={FilesView} />
            <Route path="/chat" component={Chat} />
            <Route path="/transcript" component={Transcript} />
            <Route path="/user" component={User} />
            <Route path="/data-grid-test" component={DataGridTest} />
            <Route path="/features" component={FeaturesView} />
            <Route path="/pathway" component={SelectPathway}/>
            <Route path="/sign-in" component={SignIn} />
          </Switch>
        </Container>
      </div>
    </Router>
    </Auth0Provider>
  );
};

export default App;
