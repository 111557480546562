import React, { useState, useEffect } from 'react';
import Outerbox from "../components/Outerbox"

const SelectPathway = () => {
    return(
        <div>
    <Outerbox/>
    </div>
    )
}
export default SelectPathway