import React, { useState } from 'react';
import MenuIcon from './IconComponent';
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import { Sidenav} from 'rsuite';
import {Link} from "react-router-dom";
import "./Sidebar.css"
import {SidebarItems} from "./SidebarItems"
import { NavLink as RouterNavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
// Sidebar component



// Main component
function SideBar() {
  // State to manage the visibility of the sidebar
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // Function to handle the menu icon click
  const handleMenuClick = () => {
    // Toggle the sidebar state
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
    <div className = 'sidebar'>
        <Link to = '#' className = 'menu-bars' onClick={handleMenuClick}>
            <MenuIcon/>
        </Link>
    </div>
    <nav className={isSidebarOpen ? "menu active" : "menu"}>
        <hr id = "sidebar-line"/>
        <ul className = "menu-list">
            <li>
                <Link to ="#" className = "menu-bars" onClick={handleMenuClick}>
                    <MenuIcon/>
                </Link>
            </li>
            {SidebarItems.map((item, index) => {
                return(
                    <li key={index} className={item.name}>
                        <Link to={item.path}>
                            <span>{item.title}</span>
                        </Link>
                    </li>
                )
            })}
        </ul>
    <Dropdown/>
    </nav>
    </>
  );
}

export default SideBar;
