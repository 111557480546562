import React from 'react';
import SearchBar from './SearchBar';
import {MenuIcon,BellIcon, BellBackground, SettingIcon, SettingBackground} from "./IconComponent"
import Sidebar from './Sidebar'
import {Link} from "react-router-dom";
import "./Header.css"
const Header = () => {   
  return (
    <header className = 'header'>
        <Sidebar/>
        {/* <BellBackground/>
        <BellIcon/>
        <SettingBackground/>
        <SettingIcon/> */}
        <Link to="/" class="company-name">
          <span>
              Magnifico
          </span>
        </Link>
      {/* <SearchBar /> */}
    </header>
  );
}

export default Header;