import React, { useState, useEffect } from 'react';
import { 
  DataGrid , GridToolbar, GridToolbarContainer, GridToolbarExport, 
  GridPrintGetRowsToExportParams,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons } from '@mui/x-data-grid';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

const apiUrl = process.env.REACT_APP_MAGNIFICO_BASE_URL;

function FeatureDataGrid() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useAuth0();
    const [rowModesModel, setRowModesModel] = React.useState({});
    
    const handleEditClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
  
    const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id) => () => {
      setRows(rows.filter((row) => row.id !== id));
    };
  
    const handleCancelClick = (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };

    const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    };
  
    const handleRowEditStop = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
    
    useEffect(() => {
        setLoading(true);
        const getFeaturesForCurrentUser = async () => {
            try {
                const encodedUserId = encodeURIComponent(user.sub);
                const url = `${apiUrl}/features?userID=${encodedUserId}`;
                console.log('Constructed URL:', url); // Log the URL
                const response = await axios.get(url);
            if (response.status === 200) {
                const featureData = response.data.map((row) => ({
                    id: row._id.$oid,
                    feature: row.feature,
                    source_type: row.source_type,
                    file_name: row.filename,
                    userID: row.userID,
                }));
                console.log(response)
                setRows(featureData);

            }else{
            console.error('Failed to retrieve files. Unexpected status code:', response.status);
            }

        } catch (error){
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    getFeaturesForCurrentUser();
}, []);

const columns = [
    { field: 'feature', headerName: 'Feature', width: 500 , editable:true},
    { field: 'source_type', headerName: 'Source Type', width: 200 },
    { field: 'file_name', headerName: 'File Name', width: 200 },
    { field: 'actions', type: 'actions', headerName: 'Actions', width: 100, cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ];
      }
    }
  ];

  function CustomToolbar(props) {
    const { csvOptions, printOptions} = props
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={csvOptions} printOptions={printOptions}/>
      </GridToolbarContainer>
    );
  }
  const getSelectedRowsToExport = ({ apiRef }) => {
    const selectedRowIds = selectedGridRowsSelector(apiRef);
    if (selectedRowIds.size > 0) {
      return Array.from(selectedRowIds.keys());
    }
  
    return gridFilteredSortedRowIdsSelector(apiRef);
  };
  return (
    <div style={{ height: 400, width: '100%' }}>
        <DataGrid checkboxSelection disableRowSelectionOnClick editMode="row" 
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        rows={rows} columns={columns} pageSize={5} loading={loading} slots={{toolbar : CustomToolbar}}
        slotProps={{ toolbar: { 
            csvOptions: { fileName: "Data" },
            printOptions: {
                getRowsToExport: getSelectedRowsToExport
              }
        } }} />
    </div>
);
};

export default FeatureDataGrid;