import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink as RouterNavLink } from "react-router-dom";
import React, { useState } from "react";
import "./Dropdown.css"
import {
    Collapse,
    Container,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

function Dropdown (){

  const[isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const toggle = () => {setIsOpen(!isOpen)}
  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });
  return(
<div className="nav-container">
    <Navbar className="nav-item" light expand="xl" container={false}>
  <Container>
  <NavbarToggler onClick={toggle} />
  <Collapse isOpen={isOpen} navbar>
    <Nav className="mr-auto" navbar>
  {isAuthenticated && (
        <UncontrolledDropdown nav inNavbar direction='up'>
          <DropdownToggle nav caret id="profileDropDown">
            <img
              src={user.picture}
              alt="Profile"
              className="nav-user-profile rounded-circle"
              width="50"
            />
           </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{user.name}</DropdownItem>
            <DropdownItem
              tag={RouterNavLink}
              to="/profile"
              className="dropdown-profile"
              activeClassName="router-link-exact-active"
            >
              <FontAwesomeIcon icon="user" className="mr-3" /> Profile
            </DropdownItem>
            <DropdownItem
              id="qsLogoutBtn"
              onClick={() => logoutWithRedirect()}
            >
              <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
              out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
  </Nav>
  </Collapse>
  </Container>
  </Navbar>
 </div>
  )
};

export default Dropdown;