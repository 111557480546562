export const SidebarItems = [
    {
        title: 'File Upload',
        path: '/file-upload',
        name:"sidebar-items"
    },
    {
        title:'Transcripts',
        path: '/transcript',
        name:"sidebar-items"
    },
    {
        title:"Features",
        path:'/features',
        name:"sidebar-items"
    }
    // {
    //     title:"Select Pathway",
    //     path:"/pathway",
    //     name:"sidebar-items"
    // }
]