import React from 'react';
import Message from './Message';

function ChatHistory({ chatHistory }) {
    return (
        <div 
            className="chat-history"
            style={{
                width: '80%',
                position: 'absolute',
                bottom: 'calc(20px + 56px + 10px)',  // height of TextField (56px) + bottom of TextField (20px) + a small gap (10px)
                left: '10%',
                overflowY: 'auto',  // to handle vertical overflow due to multiple messages
            }}
        >
            {chatHistory.map((message, index) => (
                <Message key={index} user={message.user} content={message.content} />
            ))}
        </div>
    );
}

export default ChatHistory;
