const contentData = [
  {
    title: "Upload and Connect Your Existing Data",
    link: "https://test.magnifico.ai/file-upload",
    description:
      "Magnifico makes it easy to upload existing files so that you can chat and interact with them. We're also planning integrations with major data stores (coming soon!)"
  },
  {
    title: "Automatically Generate Summaries of Your Content",
    link: "https://test.magnifico.ai/transcript",
    description:
      "Magnifico can quickly summarize your data, making it easy for your team to extract the relevant information and know where to go for key insights."
  },
  {
    title: "Chat with your data",
    link: "https://test.magnifico.ai/features",
    description:
      "Once your data has been embeded, you can chat with that data and ask questions."
  },
  {
    title: "Discover New Features and User Stories",
    link: "https://test.magnifico.ai/features",
    description:
    "Magnifico automatically extracts data from your user interviews and captures the relevant features, taking the drudgery out of product management and letting you focus on the parts of your job that you love."
  }
];

export default contentData;
