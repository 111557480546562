import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import FeatureDataGrid from "../components/FeatureDataGrid";
import DownloadButton from "../components/ButtonFeatureCSV";
import './view.css'
const FeaturesView = () => {
  const [featureData, setFeatureData] = useState([]); 
  const { user, isAuthenticated } = useAuth0();
    useEffect(() => {
        const fetchData = async () => {
            try {
              if (!isAuthenticated) {
                // If user is not authenticated, exit the function
                return;
              }

              // Get the Auth0 user ID
              const userId = encodeURIComponent(user.sub);
              const apiUrl = `${process.env.REACT_APP_MAGNIFICO_BASE_URL}/features?userID=${userId}`;

              // Fetch data using the bearer token
              const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
      
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
      
              const data = await response.json();
              setFeatureData(data); 
            } catch (error) {
              console.error('There was an error fetching data:', error);
            }
          };

        fetchData();
    }, [user, isAuthenticated]);

    return (
      <div className = 'main-view'>
        <div>
          <DownloadButton />
          <h2>Features</h2>
          <FeatureDataGrid featureData={featureData} />
          </div>
        </div>
    );
  };

export default FeaturesView;