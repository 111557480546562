import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Snackbar, Card, CardContent } from '@mui/material';
import { green } from '@mui/material/colors';
import FilesDataGrid from '../components/FilesDataGrid';
import Dropzone from '../components/Dropzone';
import DeterminateProgressBar from '../components/DeterminateProgressBar';
import AWS from 'aws-sdk';
import './view.css'
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: 'us-east-1',
});

const s3 = new AWS.S3();

const FilesView = () => {
    const history = useHistory();
    const { isAuthenticated, user, loginWithRedirect, getIdTokenClaims } = useAuth0();
    const [errorMessage, setErrorMessage] = useState(null); // Introduce error message state
    const [s3FilePath, setS3FilePath] = useState(null); // State to store the S3 file path  
    
    React.useEffect(() => {
        if (!isAuthenticated) {
          loginWithRedirect();  // Redirect to login page if not authenticated
        }
      }, [isAuthenticated, loginWithRedirect]);
    
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('idle');
    
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleDrop = files => {
        if (!user) return;
        if (files.length > 0) {
          const file = files[0];
          const filename = file.name.replace(/[^a-zA-Z0-9.\-_]/g, '_')
          const filePath = `${user.sub}/${filename}`;  // Construct file path with username
          const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            Key: filePath,
            Body: file,
            ContentType: file.type,
            ACL: 'private',
          };
    
          setUploadStatus('uploading');
    
          const upload = s3.upload(params);
          
          upload.on('httpUploadProgress', (evt) => {
            // Calculate the progress as a percentage
            const progressPercentage = (evt.loaded / evt.total) * 100;
            setUploadProgress(progressPercentage);
          });
          
          upload.send((err, data) => {
            if (err) {
              console.error(`Upload Error: ${err}`);
              setErrorMessage('Error uploading the file. Please try again.'); // Set error message to state
              return;
            }
            console.log('Upload to S3 successful!', data);
            setErrorMessage(null);
            setUploadProgress(0); 
            setUploadStatus('completed');
            setS3FilePath(data.Location); // Store the S3 file path after successful upload 
          });
        }
      };

    const handleCloseSnackbar = () => {
    setErrorMessage(null); // Reset error message when Snackbar is closed
    };

    // List files
    useEffect(() => {
      if (!isAuthenticated) {
        return;
      }
      const fetchData = async () => {
        try {
          // Get the Auth0 user ID
          const { __raw: accessToken } = await getIdTokenClaims();
          const userId = encodeURIComponent(accessToken.sub);
          const apiUrl = `${process.env.REACT_APP_MAGNIFICO_BASE_URL}/files?userID=${userId}`;
  
          // Fetch data using the bearer token
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const files = await response.json();
          setFiles(files);
          setLoading(false);
        } catch (error) {
          console.error('There was an error fetching data:', error);
          setLoading(false);
        }
      };
  
      fetchData();
    }, [isAuthenticated]);
  
    if (!isAuthenticated) {
      return null; // Render nothing if not authenticated
    }
  
    console.log('Files data:', files);

    return (
        <div className='main-view'> 
            {uploadStatus === 'idle' && <Dropzone onDrop={handleDrop} accept="video/*" />}

            {uploadStatus === 'uploading' && (
            <Card>
                <CardContent>
                <Typography variant="h6">Uploading your files...</Typography>
                <DeterminateProgressBar progress={uploadProgress} />
                </CardContent>
            </Card>
            )}
            {uploadStatus === 'completed' && (
                <Card style={{ backgroundColor: green[100] }}>
                <CardContent>
                    <Typography variant="h6">Upload completed successfully!</Typography>
                </CardContent>
                </Card>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
            {errorMessage && (
                <Snackbar 
                open={!!errorMessage}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={errorMessage}
                />
            )}
            </div>
            <h2>Files</h2>
            <FilesDataGrid />
        </div>
    );
}

export default FilesView;