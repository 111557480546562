import React, { useState } from 'react';
import ChatHistory from '../components/ChatHistory';
import UserInput from '../components/UserInput';
import '../chat.css';

function ChatView() {
    const [userQuestion, setUserQuestion] = useState('');
    const [chatHistory, setChatHistory] = useState([]);

    const handleSend = () => {
        if (userQuestion.trim() !== '') {
            setChatHistory([...chatHistory, { user: true, content: userQuestion }]);
            setUserQuestion('');
        }
    };

    return (
        <div className="App">
            <UserInput 
                value={userQuestion} 
                onChange={e => setUserQuestion(e.target.value)} 
                onSend={handleSend} 
            />
            <ChatHistory chatHistory={chatHistory} />
        </div>
    );
}

export default ChatView;
