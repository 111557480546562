import React from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';

export default function NextButton(props) {
  const { onPress, title = 'Next' } = props;
  return (
    <Pressable style={styles.button} onPress={onPress}>
      <Text style={styles.text}>{title}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "10px",
    backgroundColor: "#7742B9",
    width: "250px",
    height: "35px",
    top:"40px",
    flexShrink: 0
  },
  text: {
    color: '#FFF',
    fontFamily: '"Public Sans", sans-serif',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '23px',
    letterSpacing: '0.2px',
    userSelect:"none"
  },
});
