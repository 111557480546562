import React, {useState} from 'react';
import "./Outerbox.css"
import { Button} from 'react-bootstrap';
import { Text, StyleSheet, Pressable } from 'react-native';
import NextButton from "./NextButton"
import './Product.css'
import { NewproductIcon, ExistingproductIcon } from './IconComponent';
import {CompanyName,CompanyURL} from './CompanyInput';
import Existingproduct from './Existingproduct';
const Outerbox = () => {
    const [isNewProduct, setNewProduct] = useState(false);
    const [isExistingProduct, setExistingProduct] = useState(false);

    const handleNewProductClick = () => {
        setNewProduct(!isNewProduct);
        if (isExistingProduct) {
            setExistingProduct(false);
        }
      };
      
    const handleExistingProductClick = () => {
        setExistingProduct(!isExistingProduct);
        if (isNewProduct) {
            setNewProduct(false);
        }
      };
      
    return (
    <div className="outerbox">
        <h1 className = 'get-started'>GET STARTED</h1>
        <p className='ai-pathway'>Select your AI pathway</p>
        {/* <p className='select-product'>You can choose to create a new product or improve your existing product.</p> */}
        <hr className = "line"/>
        <div className = "company-name-input">
        <CompanyName/>
      </div>
      <div style={{marginTop:"15px"}}>
      <CompanyURL/>
      </div>
        <div className="aParent">
            <div className = {isNewProduct ?  "new-product select" : "new-product"} onClick={handleNewProductClick}>
                <NewproductIcon/>
                <label style={{
                            color: "#1D2733",
                            textAlign: "center",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "23px",
                            marginLeft:"5px",
                            marginRight:"5px"
                            }}>
                <h1 className="product-title">Create New Product</h1>
                We’ll guide you through creating a new product, 
                with AI supported creativity from the beginning. Through an interactive dialogue, 
                we’ll identify an opportunity and target customer, 
                creating strategy documents and feature requirements along the way.
                </label>
                <div className="circle">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle className = {isNewProduct ? "outer-circle select" : "outer-circle"} cx="12" cy="12" r="11" />
                    <circle className = {isNewProduct ? "inner-circle select" : "inner-circle"} cx="12" cy="12" r="8" />
                </svg>
                </div>
            </div>
            <div className = {isExistingProduct ?  "existing-product select" : "existing-product"} onClick={handleExistingProductClick}>
                <ExistingproductIcon/>
                <label style={{
                            color: "#1D2733",textAlign: "center",fontSize: "16px",
                            fontStyle: "normal",fontWeight: 400,lineHeight: "23px",marginLeft:"5px",marginRight:"5px"
                            }}>
                <h1 className="product-title">Improve my existing product</h1>
                Build a comprehensive view of your customer feedback, 
                beginning with a free assessment. Pull insights from customer calls 
                and support tickets to prioritize features with data driven precision.
                </label>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle className = {isExistingProduct ? "outer-circle select" : "outer-circle"} cx="12" cy="12" r="11" />
                    <circle className = {isExistingProduct ? "inner-circle select" : "inner-circle"} cx="12" cy="12" r="8" />
                </svg>
            </div>
        </div>
        <div style={{display:"inline-block"}}>
            <NextButton/>
        </div>
    </div>
  );
};

export default Outerbox;