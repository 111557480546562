import React, { useState } from 'react';
import {CheckIcon, CheckIconActive} from './IconComponent'
import './CompanyInput.css'

export const CompanyName = () => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
            <div className="input-wrapper">
        <div className='input-icon'>
        {inputValue.trim() == '' ? (
            <CheckIcon/>
        ) : (
            <CheckIconActive/>
        )}
        </div>
      <input
        type="text"
        className="input-box"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter company name"
      />
    </div>
  );
};

export const CompanyURL = () => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
      <div className="input-wrapper">
          <div className='input-icon'>
          {inputValue.trim() == '' ? (
              <CheckIcon/>
          ) : (
              <CheckIconActive/>
          )}
          </div>
      <input
        type="text"
        className="input-box"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter company website/url"
      />
    </div>
  );
};

export default CompanyName;