import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function UserInput({ value, onChange, onBlur, onSend }) {
    const handleKeyPress = (event) => {
        // If Enter is pressed without Shift
        if (event.key === 'Enter' && !event.shiftKey && value.trim() !== '') {
            // Prevent the default Enter behavior (e.g., form submission)
            event.preventDefault();
            onSend();
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 20, left: '10%', width: '80%' }}>
            <TextField
                fullWidth
                multiline  // Enables multi-line input
                rowsMax={4}  // Optionally set a maximum number of visible rows
                label="Ask a question about your documents"
                variant="outlined"
                value={value}
                onChange={onChange}
                onKeyPress={handleKeyPress}
            />
            <Button onClick={onSend} sx={{ marginLeft: 1 }}>Send</Button>
        </Box>
    );
}

export default UserInput;
