import React from 'react';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({ onDrop, accept }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  return (
    <div
      {...getRootProps()}
      style={{
        height: '200px',
        width: '100%', 
        border: '1px dashed',
        backgroundColor: isDragActive ? 'lightgrey' : '#f3f3f3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', 
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>
          Drag 'n' drop some files here, or click to select files.
          Any format can be accepted to upload, however transcripts can only be created from these file formats: MP3, MP4, WAV, FLAC, AMR, OGG, and WebM
        </p>
      )}
    </div>
  );
};

export default Dropzone;


