import React from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';



const apiUrl = process.env.REACT_APP_MAGNIFICO_BASE_URL;

const DownloadButton = () => {
    const { user } = useAuth0();
    const encodedUserId = encodeURIComponent(user.sub);
    const url = `${apiUrl}/features?userID=${encodedUserId}`;
    
    const handleDownload = async () => {
        try {
            const response = await axios.get(url);

            const featureData = response.data.map((row) => ({
                feature: row.feature,
                source_type: row.source_type,
                file_name: row.filename,
            }));
            function convertToCSV(arr) {
                const array = [Object.keys(arr[0])].concat(arr)
              
                return array.map(it => {
                  return Object.values(it).toString()
                }).join('\n')
              }
            
              const csvContent = convertToCSV(featureData);
              // Create a Blob from the CSV content
              const file = new Blob([csvContent], {
                  type: 'text/csv',
              });

            // Build a URL from the file
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor to trigger download
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'data.csv');
            document.body.appendChild(fileLink);
            
            fileLink.click();
            document.body.removeChild(fileLink);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    return <button onClick={handleDownload}>Download CSV</button>;
};

export default DownloadButton;
