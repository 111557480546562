import React, { Fragment } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import './view.css'
const Home = () => (
  <div className='main-view'>
  <Fragment>
    <Hero />
    <hr />
    <Content />
  </Fragment>
  </div>
);

export default Home;
