import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const User = () => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [userData, setUserData] = useState(null);
  const [bearerToken, setBearerToken] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiBaseUrl = process.env.REACT_APP_MAGNIFICO_BASE_URL;

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
  
        if (!isAuthenticated) {
          setError('User is not authenticated.');
          return;
        }
  
        const token = await getAccessTokenSilently();
        setBearerToken(token);  // Save the bearer token in the state
        const response = await fetch(`${apiBaseUrl}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        } else {
          setError(`Failed to fetch user data: ${response.status} ${response.statusText}`);
        }
      } catch (error) {
        setError(`Failed to fetch: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, apiBaseUrl, isAuthenticated]);

  return (
    <div>
      <h1>User Data</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {bearerToken && <div><h2>Bearer Token</h2><pre>{bearerToken}</pre></div>}
      {user && <div><h2>Auth0 User Details</h2><pre>{JSON.stringify(user, null, 2)}</pre></div>}
      {userData && <div><h2>API User Data</h2><pre>{JSON.stringify(userData, null, 2)}</pre></div>}
    </div>
  );
};

export default User;
